.header-logo {
    max-width: 8rem;
    max-height: 5rem;
}

.header-logo:hover{
    cursor: pointer;
}

.flag {
    max-height: 1.5rem;
    width: auto;
}
