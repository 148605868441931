textarea {
  border: 1px rgb(189, 189, 189) solid;
  min-height: 100px;
  /* height: 100px; */
  padding: 4px;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
  width: 100%;
}

.date-time-picker {
  background-color: #ffffff;
}
