.signin-logo{
    max-width: 20rem;
    max-height: 10rem;
}

.signin-box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
